import React, { useEffect, useContext } from 'react'
import { Grid, Typography } from "@mui/material";
import { GrHomeRounded } from "react-icons/gr";
import { AiFillInfoCircle, AiOutlineMenu, AiOutlineSetting, AiOutlineUser } from "react-icons/ai";
import { BsChatSquareText, BsWallet } from "react-icons/bs";
import { FaFacebook, FaInfoCircle } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import { useState } from 'react';
import logo from './logo.png'
import logoFooter from './serendepia2.png'
import { SlTrophy } from "react-icons/sl";
import { IoGameControllerOutline } from "react-icons/io5";
import config from '../../config';
import AuthContext from '../../AuthContext';
import { SiEventstore } from "react-icons/si";
import { TbHelpHexagonFilled, TbLogout2, TbMoneybag } from "react-icons/tb";
import './Layout.css'
import Auth from '../Auth/Auth';
import usdt from './usdt.svg'
import { IoIosArrowDown, IoIosArrowUp, IoIosHelpCircleOutline } from 'react-icons/io';
import DropdownPurchase from './purchase/DropdownPurchase';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import MultiEventTimer from './MultiEventTimer';
import { BiLogoDiscord } from 'react-icons/bi';
import axios from 'axios';

function Layout(props) {
    const { user } = useContext(AuthContext);
    const [pulse, setPulse] = useState(false);
    const [abrir, setAbrir] = useState(true);
    const [abrirEventos, setAbrirEventos] = useState(true);
    const [aparecer, setAparcer] = useState(false);
    const [generalData, setGeneralData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [coins, setCoins] = useState(false);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.apiBaseUrl}/info`);
                const data = await response.json();
                setGeneralData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setGeneralData(null);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const fetchNews = async () => {
        try {
            const response = await axios.get(`https://backmu.vercel.app/infoUser/user/${user?.username}`);
            // Ordenar las noticias por la fecha de creación en orden descendente (primero la más reciente)
            setCoins(response.data.cashShopData)
           
        } catch (error) {
            console.error('Error al obtener noticias:', error);
        }
    };

    useEffect(() => {
        fetchNews()
    }, [user])

    const [showPopupAuth, setShowPopupAuth] = useState(false);
    const [type, setType] = useState(0);

    function clickPulsedButton() {
        setPulse(!pulse);
        props.setExpanded(!props.tyExpanded);
        setTimeout(() => {
            if (pulse) {
                setAparcer(true);
            } else {
                setAparcer(false);
            }
        }, 500);
    }

    function togglePopupAuth(type) {
        setShowPopupAuth(!showPopupAuth);
        setType(type);
    }

    function cerrarCanalesRecomendados() {
        setAbrir(!abrir);
    }

    function cerrarEventos() {
        setAbrirEventos(!abrirEventos);
    }
    const handleLogout = async () => {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("account");
        window.location.href = "/";
    };
    let location = useLocation();
    const [subMenu, setSubMenu] = useState(false);
    const [esClick, setEsClick] = useState(false);
    const habilitarSubMenu = (valor, e) => {
        if (e?.type === "click") {
            setEsClick(true);
        } else {
            setEsClick(false);
        }
        setTimeout(() => {
            setSubMenu(valor);
        }, [100]);
    };
    useEffect(() => {
        const handleClickOutside = () => {
            setSubMenu(false);
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    const [expandCartera, setExpandCartera] = useState(false);

    const handleExpandCartera = () => {
        setExpandCartera(!expandCartera);
    };
    const getNavDesktop = () => {
        return (
            <Grid
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "105% !important",
                    minHeight: '100vh'
                }}
            >
                {!props.isMobile &&
                    <Grid
                        style={{
                            width: props.tyExpanded ? "13%" : "4%",
                            transition: "width .2s ease-in-out",
                            border: "1px solid #2a2e38",
                            backgroundColor: "#121418",
                            position: "sticky",
                            top: 0,
                            zIndex: 999999
                        }}
                    >
                        <Grid
                            style={{
                                padding: props.tyExpanded ? "1.54rem 20px" : "2.28rem 5px",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: props.tyExpanded ? "space-between" : 'center',
                            }}
                        >
                            <AiOutlineMenu
                                style={{ color: "white", fontSize: "26px" }}
                                onClick={() => clickPulsedButton()}
                                className="img-bars"
                            />
                            {props.tyExpanded && (


                                <Grid
                                    style={{
                                        display: "flex",
                                        textAlign: "center",
                                        alignItems: "center",
                                        borderRadius: ".375rem",

                                        width: "85%",
                                    }}
                                    className="contenedor-directos-cat"
                                >

                                    <Link
                                        style={{ textDecoration: "none", padding: 0, width: '100%' }}
                                        to="/descargas"
                                    >
                                        <Grid
                                            style={{
                                                height: "3rem",
                                                color: "white",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: ".5rem",
                                                borderRadius: ".375rem",
                                                width: "100%",
                                                justifyContent: "center",
                                                animation: !props.tyExpanded && "ease-in-out 1.5s linear",
                                                backgroundColor: '#cd1009'
                                            }}
                                            className='boton-descarga'

                                        >
                                            <span
                                                style={{
                                                    fontSize: "12px",
                                                    fontWeight: 500,
                                                    textShadow: 'rgb(0, 0, 0) 0px 1px 0px',
                                                    fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif'
                                                }}

                                            >
                                                Descargar el cliente!
                                            </span>
                                        </Grid>
                                    </Link>
                                </Grid>

                            )}
                        </Grid>
                        <Grid>
                            <Grid
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                    borderTop: '1px solid rgb(42, 46, 56)'
                                }}
                            >
                                <ul
                                    style={{
                                        listStyle: "none",
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        transition: "all 1s ease",
                                        padding: 0
                                    }}
                                >
                                    <Link
                                        style={{ textDecoration: "none" }}
                                        className="menu-aside-option"
                                        to="/"
                                    >
                                        <li
                                            style={{
                                                color: "white",
                                                display: "flex",
                                                gap: "10px",
                                                alignItems: "center",
                                                width: "100% !important",
                                                padding: props.tyExpanded ? "0rem 15px" : "0px",
                                                justifyContent: !props.tyExpanded && "center",
                                                animation: !props.tyExpanded && "ease-in-out 1s linear",
                                            }}
                                            className={
                                                location.pathname === "/" ? "item-liActive" : "item-li"
                                            }
                                        >
                                            <GrHomeRounded />
                                            {props.tyExpanded && <span>Inicio</span>}
                                        </li>
                                    </Link>
                                    <Link
                                        style={{ textDecoration: "none" }}
                                        className="menu-aside-option"
                                        to="/rankings"
                                    >
                                        <li
                                            style={{
                                                color: "white",
                                                display: "flex",
                                                gap: "10px",
                                                alignItems: "center",
                                                padding: props.tyExpanded ? "0rem 15px" : "0px",
                                                justifyContent: !props.tyExpanded && "center",
                                                animation: !props.tyExpanded && "ease-in-out 1s linear",
                                            }}
                                            className={
                                                location.pathname === "/rankings" ? "item-liActive" : "item-li"
                                            }
                                        >
                                            <SlTrophy />
                                            {props.tyExpanded && <span>Rankings</span>}
                                        </li>
                                    </Link>
                                    {/* <Link
                                        style={{ textDecoration: "none" }}
                                        className="menu-aside-option"
                                        to="/foro"
                                    >
                                        <li
                                            style={{
                                                color: "white",
                                                display: "flex",
                                                gap: "10px",
                                                alignItems: "center",
                                                padding: props.tyExpanded ? "0rem 15px" : "0px",
                                                justifyContent: !props.tyExpanded && "center",
                                                animation: !props.tyExpanded && "ease-in-out 3s linear",
                                            }}
                                            className={
                                                location.pathname === "/foro" ? "item-liActive" : "item-li"
                                            }
                                        >
                                            <BsChatSquareText />
                                            {props.tyExpanded && <span>Foro</span>}
                                        </li>
                                    </Link> */}


                                    <Link
                                        style={{ textDecoration: "none" }}
                                        className="menu-aside-option"
                                        to="/ayuda"
                                    >
                                        <li
                                            style={{
                                                color: "white",
                                                display: "flex",
                                                gap: "10px",
                                                alignItems: "center",
                                                padding: props.tyExpanded ? "0rem 15px" : "0px",
                                                justifyContent: !props.tyExpanded && "center",
                                                animation: !props.tyExpanded && "ease-in-out 3s linear",
                                            }}
                                            className={
                                                location.pathname === "/ayuda" ? "item-liActive" : "item-li"
                                            }
                                        >
                                            <TbHelpHexagonFilled />
                                            {props.tyExpanded && <span>Guías</span>}
                                        </li>
                                    </Link>
                                </ul>
                            </Grid>
                            <Grid
                                style={{
                                    backgroundColor: "#080808",
                                    padding: "0.5rem 15px",
                                    borderTop: "1px solid #2a2e38",

                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                }}
                            >
                                {props.tyExpanded ? (
                                    <>
                                        <Grid
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                alignItems: "center",
                                                padding: "5px",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Grid style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                <AiFillInfoCircle style={{ color: abrir ? '#ed3d3d' : "white" }} />
                                                <span
                                                    style={{
                                                        color: abrir ? '#ed3d3d' : "white",
                                                        fontFamily: "Inter",
                                                        fontWeight: "600",
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    INFORMACIÓN
                                                </span>
                                            </Grid>
                                            {abrir ?
                                                <FaArrowUp style={{
                                                    color: "white",
                                                    marginLeft: "5px",
                                                    backgroundColor: "#080808",
                                                    cursor: "pointer",
                                                    transform: abrir ? "rotate(0deg)" : "rotate(-180deg)",
                                                    transition: "all 0.5s ",
                                                }}
                                                    onClick={() => cerrarCanalesRecomendados()}
                                                />
                                                :
                                                <FaArrowUp style={{
                                                    color: "white",
                                                    marginLeft: "5px",
                                                    backgroundColor: "#080808",
                                                    cursor: "pointer",
                                                    transform: abrir ? "rotate(0deg)" : "rotate(-180deg)",
                                                    transition: "all 0.5s ",
                                                }}
                                                    onClick={() => cerrarCanalesRecomendados()}
                                                />
                                            }
                                        </Grid>
                                        <Grid
                                            style={{
                                                display: abrir ? "flex" : "none",
                                                flexDirection: "column",
                                                gap: "15px",
                                                maxHeight: abrir ? "100% !important" : "0px !important",
                                                transition: "all 0.5s ease-out",
                                            }}
                                        >
                                            <ul style={{ margin: 0, display: 'flex', flexDirection: 'column', gap: '10px', padding: '5px' }}>
                                                {!isLoading && (
                                                    <li style={{ color: 'white', display: 'flex', gap: '5px', alignItems: 'center' }}>
                                                        {generalData && generalData.jugadoresOnline > 0 ? (
                                                            <span id="pulsatingDot" style={{ marginRight: '5px' }} />
                                                        ) : (
                                                            <span id="pulsatingDotOff" style={{ marginRight: '5px' }} />
                                                        )}
                                                        Server
                                                        {generalData && generalData.jugadoresOnline > 0 ? (
                                                            <span style={{ color: '#ffffff99', backgroundColor: 'green', borderRadius: '7px', fontSize: '14px', padding: 3 }}>Online</span>
                                                        ) : (
                                                            <span style={{ color: '#ffffff99', backgroundColor: 'red', borderRadius: '7px', fontSize: '14px', padding: 3 }}>Mantenimiento</span>
                                                        )}
                                                    </li>
                                                )}
                                                {isLoading && <li style={{ color: 'white' }}>Cargando...</li>}
                                                <li style={{ color: 'white', display: 'flex', gap: '5px', alignItems: 'center' }}> <IoGameControllerOutline />Versión: Legendaria</li>
                                                <li style={{ color: 'white', display: 'flex', gap: '5px', alignItems: 'center' }}> <IoGameControllerOutline />Experiencia: 100x</li>
                                                <li style={{ color: 'white', display: 'flex', gap: '5px', alignItems: 'center' }}> <IoGameControllerOutline />Drop: 40%</li>
                                                <Link to='/informacion' style={{ textDecoration: 'none' }}>
                                                    <li style={{ color: 'white', display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }} className='item-hover'>
                                                        <h6
                                                            className="button-navbar-register"

                                                        >
                                                            Ver más
                                                        </h6></li>
                                                </Link>
                                            </ul>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <AiFillInfoCircle style={{ color: 'white', margin: '0 auto' }} />
                                    </>
                                )}
                            </Grid>
                            <Grid
                                style={{
                                    backgroundColor: "#080808",
                                    padding: "0.5rem 15px",
                                    borderTop: "1px solid #2a2e38",

                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                }}
                            >
                                {props.tyExpanded ? (
                                    <>
                                        <Grid
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                alignItems: "center",
                                                padding: "5px",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Grid style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                <SiEventstore style={{ color: abrirEventos ? '#ed3d3d' : "white" }} />
                                                <span
                                                    style={{
                                                        color: abrirEventos ? '#ed3d3d' : "white",
                                                        fontFamily: "Inter",
                                                        fontWeight: "600",
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    EVENTOS
                                                </span>
                                            </Grid>
                                            {abrirEventos ?
                                                <FaArrowUp style={{
                                                    color: "white",
                                                    marginLeft: "5px",
                                                    backgroundColor: "#080808",
                                                    cursor: "pointer",
                                                    transform: abrirEventos ? "rotate(0deg)" : "rotate(-180deg)",
                                                    transition: "all 0.5s ",
                                                }}
                                                    onClick={() => cerrarEventos()}
                                                />
                                                :
                                                <FaArrowUp style={{
                                                    color: "white",
                                                    marginLeft: "5px",
                                                    backgroundColor: "#080808",
                                                    cursor: "pointer",
                                                    transform: abrirEventos ? "rotate(0deg)" : "rotate(-180deg)",
                                                    transition: "all 0.5s ",
                                                }}
                                                    onClick={() => cerrarEventos()}
                                                />
                                            }
                                        </Grid>
                                        <Grid
                                            style={{
                                                display: abrirEventos ? "flex" : "none",
                                                flexDirection: "column",
                                                gap: "15px",
                                                maxHeight: abrir ? "100% !important" : "0px !important",
                                                transition: "all 0.5s ease-out",
                                            }}
                                        >
                                            <ul style={{ margin: 0, display: 'flex', flexDirection: 'column', gap: '10px', padding: '5px' }}>
                                                <MultiEventTimer />
                                            </ul>
                                        </Grid>


                                    </>
                                ) : (
                                    <>
                                        <SiEventstore style={{ color: 'white', margin: '0 auto' }} />
                                    </>
                                )}

                            </Grid>

                            <h6 style={{ color: '#ffffffcc', padding: "0.5rem 15px", textAlign: 'center', fontSize: '12px' }}>
                                Copyright © 2024 Mu Serendipia
                            </h6>
                        </Grid>
                    </Grid>
                }
                {
                    props.isMobile &&
                    <div className="mobile-menu">
                        <Grid style={{ borderRight: '1px solid #2a2e38', display: 'flex', flex: '1 1', justifyContent: 'center', height: '100%' }}>
                            <Link
                                style={{ textDecoration: "none", margin: '0 auto', padding: '10px 0px' }}
                                to="/"
                            >
                                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                                    <GrHomeRounded style={{ fontSize: '14px' }} />
                                    <span style={{ fontSize: '14px' }}>Inicio</span>
                                </Grid>
                            </Link>
                        </Grid>
                        <Grid style={{ borderRight: '1px solid #2a2e38', display: 'flex', flex: '1 1', justifyContent: 'center', height: '100%' }}>
                            <Link
                                style={{ textDecoration: "none", margin: '0 auto', padding: '10px 0px' }}
                                to="/rankings"
                            >
                                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                                    <SlTrophy style={{ fontSize: '14px' }} />
                                    <span style={{ fontSize: '14px' }}>Rankings</span>
                                </Grid>
                            </Link>
                        </Grid>
                        {/* <Grid style={{ borderRight: '1px solid #2a2e38', display: 'flex', flex: '1 1', justifyContent: 'center', height: '100%' }}>
                            <Link
                                style={{ textDecoration: "none", margin: '0 auto', padding: '10px 0px' }}
                                to="/foro"
                            >
                                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                                    <BsChatSquareText style={{ fontSize: '14px' }} />
                                    <span style={{ fontSize: '14px' }}>Foro</span>
                                </Grid>
                            </Link>
                        </Grid> */}

                        <Grid style={{ borderRight: '1px solid #2a2e38', display: 'flex', flex: '1 1', justifyContent: 'center', height: '100%' }}>
                            <Link
                                style={{ textDecoration: "none", margin: '0 auto', padding: '10px 0px' }}
                                to="/informacion"
                            >
                                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                                    <FaInfoCircle style={{ fontSize: '14px' }} />
                                    <span style={{ fontSize: '14px' }}>Informacion</span>
                                </Grid>
                            </Link>
                        </Grid>

                        <Grid style={{ display: 'flex', flex: '1 1', justifyContent: 'center', height: '100%' }}>
                            <Link
                                style={{ textDecoration: "none", margin: '0 auto', padding: '10px 0px' }}
                                to="/ayuda"
                            >
                                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                                    <TbHelpHexagonFilled style={{ fontSize: '14px' }} />
                                    <span style={{ fontSize: '14px' }}>Guías</span>
                                </Grid>
                            </Link>
                        </Grid>
                    </div>
                }
                <Grid
                    style={{
                        width: props.tyExpanded ? "90%" : "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        zIndex: 997,
                        flex: 1,
                    }}
                >
                    <Grid>
                        <Grid
                            style={{
                                borderBottom: "1px solid #2a2e38",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: props.isMobile ? "0px 1rem" : user?.username ? "1.15% 5.8rem" : "0px 5.8rem",
                                position: "sticky",
                                top: 0,
                                zIndex: 998,
                                backgroundColor: "#080808",
                                width: "102%",
                                gap: props.isMobile && "6px"
                            }}
                        >
                            <Link to="/" style={{ width: props.isMobile ? '0%' : '15%' }}>
                                <span style={{ width: '100%' }}></span>
                            </Link>
                            {
                                !props.isMobile  && user?.username && (
                                    <Grid style={{ display: "flex", alignItems: "center" }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "5px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    // width: "500px",
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    cursor: "pointer",
                                                    overflow: "hidden"
                                                }}
                                                className="button-purchase-pixels"
                                                onClick={() => handleExpandCartera()}
                                            >
                                                <button
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <Grid
                                                        style={{
                                                            display: "flex",
                                                            gap: "5px",
                                                            alignItems: "center",
                                                            width: "80%",

                                                        }}
                                                    >
                                                        <RiMoneyDollarCircleFill style={{ color: 'gold', fontSize: '20px' }} />

                                                        <span style={{ fontSize: "12px", color: 'white' }}>
                                                            {`${coins.WCoinC} Golds`}
                                                        </span>
                                                    </Grid>

                                                    {expandCartera ? <IoIosArrowUp style={{ color: 'white' }} /> : <IoIosArrowDown style={{ color: 'white' }} />}
                                                </button>
                                            </div>
                                            {expandCartera && <DropdownPurchase user={user} />}

                                            <Grid onClick={() => handleExpandCartera()}>
                                                <button
                                                    style={{
                                                        borderRadius: "5px",
                                                        fontFamily:
                                                            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                        backgroundColor: "transparent",
                                                        border: "1px solid #cc110c",
                                                        color: "white",
                                                        padding: "10px",
                                                        cursor: "pointer",
                                                    }}
                                                    className="boton-comprar"
                                                >
                                                    <BsWallet />
                                                    <Typography style={{ fontSize: "14px" }}>
                                                        Comprar{" "}
                                                    </Typography>
                                                </button>
                                            </Grid>
                                        </div>
                                    </Grid>
                                )
                            }


                            {
                                props.isMobile && user?.username && (
                                    <Grid
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "60%",
                                            justifyContent: "center",
                                            height: "5rem !important",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "5px",
                                                alignItems: "center",
                                                height: "3rem !important",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    // width: "500px",
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    height: "3rem !important",
                                                    overflow: "hidden",
                                                }}
                                                className="button-purchase-pixels"
                                                onClick={() => handleExpandCartera()}
                                            >
                                                <button
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Grid
                                                        style={{
                                                            display: "flex",
                                                            gap: "5px",
                                                            alignItems: "center",
                                                            width: "80%",
                                                        }}
                                                    >
                                                        <RiMoneyDollarCircleFill style={{ color: 'yellow', fontSize: '20px' }} />
                                                        <span style={{ fontSize: "14px", color: 'white' }}>
                                                        {`${coins.WCoinC}`}
                                                        </span>
                                                    </Grid>

                                                    {expandCartera ? <IoIosArrowUp style={{ color: 'white' }} /> : <IoIosArrowDown style={{ color: 'white' }} />}
                                                </button>
                                            </div>
                                            {expandCartera && <DropdownPurchase />}

                                            <Grid onClick={() => handleExpandCartera()}>
                                                <button
                                                    style={{
                                                        borderRadius: "5px",
                                                        fontFamily: "inter",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                        backgroundColor: "#f36196 ",
                                                        padding: "10px 10px ",

                                                    }}
                                                    className="boton-comprar"
                                                >
                                                    <BsWallet style={{ fontSize: "20px", fontWeight: 600, color: 'white' }} />
                                                </button>
                                            </Grid>
                                        </div>
                                    </Grid>
                                )
                            }
                            {
                                !user?.username ?
                                    <Grid style={{ display: "flex", alignItems: "center", gap: "10px" }}>

                                        <Grid style={{ display: "flex", alignItems: "center", gap: "5px", width: '100%', borderRight: 'rgb(42, 46, 56) 1px solid', padding: '0px 10px', height: '3rem' }}>
                                            <a href='https://www.facebook.com/MuSerendipia' target='_blank' style={{ backgroundColor: '#3c569b', borderRadius: '5px', cursor: 'pointer', padding: '0px 5px', display: 'flex' }} className='icon-hover'>
                                                <FaFacebook style={{ color: 'white', fontSize: '32px' }} />
                                            </a>
                                            <a href='https://discord.com/invite/vzXpnuAHGX' target='_blank' style={{ backgroundColor: '#64707b', borderRadius: '5px', cursor: 'pointer', padding: '0px 5px', display: 'flex' }} className='icon-hover' >
                                                <BiLogoDiscord style={{ color: 'white', fontSize: '32px' }} />
                                            </a>
                                        </Grid>

                                        <h6
                                            onClick={() => togglePopupAuth(0)}
                                            className="button-navbar-login"
                                        >
                                            Ingresar
                                        </h6>
                                        <h6
                                            onClick={() => togglePopupAuth(1)}
                                            className="button-navbar-register"
                                        >
                                            Registrarse
                                        </h6>
                                    </Grid>
                                    :

                                    <Grid style={{ display: "flex", alignItems: "center", gap: "10px" }}>

                                       
                                        <div style={{ overflow: 'hidden' }}>


                                            <div
                                                style={{
                                                    width: "60px",
                                                    height: "60px",
                                                    position: "relative",
                                                    overflow: 'hidden',
                                                    backgroundColor: '#343843',
                                                    borderRadius: "50%",
                                                }}
                                                className="navbar-image-avatar"
                                                onClick={(e) => habilitarSubMenu(true, e)}
                                                onMouseEnter={
                                                    esClick
                                                        ? console.log("activo")
                                                        : () => habilitarSubMenu(true)
                                                }
                                                onMouseLeave={
                                                    esClick
                                                        ? console.log("activo")
                                                        : () => habilitarSubMenu(false)
                                                }
                                            >
                                                <img
                                                    style={{ width: '100%' }}
                                                    src={props.user?.Avatar ?? logoFooter}
                                                    alt=""
                                                />
                                            </div>


                                        </div>

                                    </Grid>

                            }

                        </Grid>
                        {subMenu && (
                            <Grid
                                onMouseEnter={
                                    esClick
                                        ? console.log("activo")
                                        : () => habilitarSubMenu(true)
                                }
                                onMouseLeave={
                                    esClick
                                        ? console.log("activo")
                                        : () => habilitarSubMenu(false)
                                }
                                style={{
                                    backgroundColor: "#121418",
                                    border: "1px solid #343843",
                                    position: "absolute",
                                    padding: "1rem",
                                    width: "16.25rem",
                                    right: "4%",
                                    top: "7%",
                                    borderRadius: "0.5rem",
                                    zIndex: 99999,
                                }}
                            >
                                <Grid
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        gap: "15px",
                                        marginTop: "15px",
                                    }}
                                >
                                    <Grid
                                        style={{
                                            backgroundColor: "#202329",
                                            borderRadius: "5px",
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "1rem",
                                            width: "100%",
                                        }}
                                    >
                                        <Grid
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "15px",
                                                padding: 10,
                                            }}
                                        >
                                            <img
                                                src={props.user?.Avatar ?? logoFooter}
                                                alt=""
                                                style={{
                                                    width: "60px",
                                                    height: "60px",
                                                    borderRadius: "50%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                            <Grid
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "1px",
                                                }}
                                            >
                                                <Link
                                                    style={{
                                                        textDecoration: "none",
                                                        margin: 0,
                                                        padding: 0,
                                                    }}
                                                    to={"/" + user?.username}
                                                >
                                                    <Typography
                                                        style={{
                                                            color: "white",
                                                            fontSize: "1rem",
                                                            fontFamily: "Inter",
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {user?.username ?? "Usuario"}
                                                    </Typography>
                                                </Link>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Link
                                        className="dropdownaccount-link"
                                        to={"/" + user?.username}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <AiOutlineUser style={{ marginRight: "10px" }} />
                                        Mi Cuenta
                                    </Link>
                                    <Link
                                        className="dropdownaccount-link"
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                        to={"/" + props.user?.NameUser + "/settings"}
                                    >
                                        <AiOutlineSetting style={{ marginRight: "10px" }} />
                                        Configuración
                                    </Link>
                                    <div
                                        className="dropdownaccount-link"
                                        onClick={() => handleLogout()}
                                        style={{ display: "flex", alignItems: 'center' }}
                                    >
                                        <TbLogout2 style={{ marginRight: "10px", color: 'white' }} />
                                        <Typography style={{ color: 'white' }}>
                                            Cerrar sesión
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                        <Grid style={{ width: "102%", backgroundColor: '#080808', height:'100vh' }} >
                            {props.children}
                            {showPopupAuth === true && (
                                <Auth
                                    isMobile={props.isMobile}
                                    typeDefault={type}
                                    closePopup={() => togglePopupAuth()}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {/* <Grid style={{
                        width: '103%',
                        display: "flex",
                        flexDirection: "column",
                        padding: props.isMobile ? "1rem 0rem" : "1.3rem 5rem",
                        zIndex: 99999,
                        backgroundColor: "rgb(18, 20, 24)",
                        gap: '2rem',
                        position: "relative",
                        bottom: 0
                    }}>
                        <Grid style={{ display: 'flex', alignItems: 'center', width: '98%', flexDirection: props.isMobile && 'column' }}>
                            <Grid style={{ width: props.isMobile ? '100%' : "20%" }}>
                                <Link to="/" style={{ margin: props.isMobile && '0 auto', display: props.isMobile && 'flex', justifyContent: props.isMobile && 'center' }}>
                                    <img
                                        src={logoFooter}
                                        style={{ width: props.isMobile ? '30%' : "60%" }}
                                        alt=""
                                    />
                                </Link>
                            </Grid>
                            <Grid style={{ display: 'flex', alignItems: 'flexStart', flexDirection: 'column', justifyContent: 'center', width: props.isMobile ? '100%' : '20%', color: 'white', textAlign: props.isMobile && 'center' }}>
                                <Typography style={{ fontWeight: 800, fontSize: '1rem', textShadow: '0 1px 0 #000' }}>Support</Typography>
                                <Typography style={{ color: '#828998', fontFamily: 'Inter' }}>Live Support</Typography>
                                <Typography style={{ color: '#828998', fontFamily: 'Inter' }}>Help Center</Typography>
                                <Typography style={{ color: '#828998', fontFamily: 'Inter' }}>Game Responsibly</Typography>
                            </Grid>
                            <Grid style={{ display: 'flex', alignItems: 'flexStart', flexDirection: 'column', justifyContent: 'center', width: props.isMobile ? '100%' : '20%', color: 'white', textAlign: props.isMobile && 'center' }}>
                                <Typography style={{ fontWeight: 800, fontSize: '1rem', textShadow: '0 1px 0 #000' }}>Support</Typography>
                                <Typography style={{ color: '#828998', fontFamily: 'Inter' }}>Live Support</Typography>
                                <Typography style={{ color: '#828998', fontFamily: 'Inter' }}>Help Center</Typography>
                                <Typography style={{ color: '#828998', fontFamily: 'Inter' }}>Game Responsibly</Typography>
                            </Grid>
                            <Grid style={{ display: 'flex', alignItems: 'flexStart', flexDirection: 'column', justifyContent: 'center', width: props.isMobile ? '100%' : '20%', color: 'white', textAlign: props.isMobile && 'center' }}>
                                <Typography style={{ fontWeight: 800, fontSize: '1rem', textShadow: '0 1px 0 #000' }}>Support</Typography>
                                <Typography style={{ color: '#828998', fontFamily: 'Inter' }}>Live Support</Typography>
                                <Typography style={{ color: '#828998', fontFamily: 'Inter' }}>Help Center</Typography>
                                <Typography style={{ color: '#828998', fontFamily: 'Inter' }}>Game Responsibly</Typography>
                            </Grid>
                            <Grid style={{ display: 'flex', alignItems: 'flexStart', flexDirection: 'column', justifyContent: 'center', width: props.isMobile ? '100%' : '20%', color: 'white', textAlign: props.isMobile && 'center' }}>
                                <Typography style={{ fontWeight: 800, fontSize: '1rem', textShadow: '0 1px 0 #000' }}>Support</Typography>
                                <Typography style={{ color: '#828998', fontFamily: 'Inter' }}>Live Support</Typography>
                                <Typography style={{ color: '#828998', fontFamily: 'Inter' }}>Help Center</Typography>
                                <Typography style={{ color: '#828998', fontFamily: 'Inter' }}>Game Responsibly</Typography>
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid >
        )
    }
    return (
        getNavDesktop()
    )
}

export default Layout