import './App.css';
import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import Home from './componentes/home/Home';
import { useContext, useEffect, useState } from 'react';
import Rankings from './componentes/rankings/Rankings';
import Informacion from './componentes/informacion/Informacion';
import Foro from './componentes/Foro/Foro';
import Tema from './componentes/Foro/Tema';
import Post from './componentes/Foro/Post';
import Descargas from './componentes/descargas/Descargas';
import { AuthProvider } from './AuthContext';
import ReactGA from 'react-ga4';
import Ayuda from './componentes/ayuda/Ayuda';
import Guia from './componentes/ayuda/Guia';
import Micuenta from './componentes/micuenta/Micuenta';
import AuthContext from "./AuthContext";

function App() {
  ReactGA.initialize('G-E9LZMDW619');

  const [expanded, setExpanded] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const RutaPrivada = ({ children }) => {
    const { usuario } = useParams();
    const { user } = useContext(AuthContext);
    


    if (!user || user.username !== usuario) {
     return <Home tyExpanded={expanded} setExpanded={setExpanded} isMobile={isMobile}/>
    }

    return children;
  };
  return (
    <AuthProvider>
      <Routes>
        <Route exact path='/' element={<Home tyExpanded={expanded} setExpanded={setExpanded} isMobile={isMobile} />} />
        <Route exact path='/rankings' element={<Rankings tyExpanded={expanded} setExpanded={setExpanded} isMobile={isMobile} />} />
        <Route exact path='/informacion' element={<Informacion tyExpanded={expanded} setExpanded={setExpanded} isMobile={isMobile} />} />
        <Route exact path='/descargas' element={<Descargas tyExpanded={expanded} setExpanded={setExpanded} isMobile={isMobile} />} />
        <Route exact path='/foro' element={<Foro tyExpanded={expanded} setExpanded={setExpanded} isMobile={isMobile} />} />
        <Route exact path='/foro/:id' element={<Tema tyExpanded={expanded} setExpanded={setExpanded} isMobile={isMobile} />} />
        <Route exact path='/foro/:temaId/:postId' element={<Post tyExpanded={expanded} setExpanded={setExpanded} isMobile={isMobile} />} />
        <Route exact path='/ayuda' element={<Ayuda tyExpanded={expanded} setExpanded={setExpanded} isMobile={isMobile} />} />
        <Route exact path='/guia/:id' element={<Guia tyExpanded={expanded} setExpanded={setExpanded} isMobile={isMobile} />} />
        {/* <Route exact path='/:usuario' element={<Micuenta tyExpanded={expanded} setExpanded={setExpanded} isMobile={isMobile} />} /> */}
        <Route
          path="/:usuario"
          element={
            <RutaPrivada>
              <Micuenta tyExpanded={expanded} setExpanded={setExpanded} isMobile={isMobile} />
            </RutaPrivada>
          }
        />

      </Routes>
    </AuthProvider>
  );
}

export default App;
